<template>
  <div id="addAuthority">
    <el-dialog
      :title="!isEdit ? '新增权限' : '编辑'"
      :visible.sync="show"
      top="20px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @closed="closedDialog"
      @open="openedDialog"
    >
      <el-form
        :model="authorityForm"
        :rules="authorityRules"
        ref="authorityForm"
        label-width="100px"
      >
        <el-form-item label="权限名称" prop="name">
          <el-input v-model="authorityForm.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <el-tree
            :data="authorityForm.menu"
            ref="menu"
            show-checkbox
            :check-strictly="true"
            node-key="path"
            :props="{
              children: 'children',
              label: 'name',
            }"
          >
          </el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span></el-dialog
    >
  </div>
</template>
<script>
import authorityMenu from "@/utils/menu.js";
export default {
  data() {
    return {
      isEdit: false,
      loading: false,
      show: false,
      //编辑时传进来的
      roleDetail: null,
      roleId: null,
      authorityForm: {
        name: "",
        menu: authorityMenu,
      },
      authorityRules: {
        name: [{ required: true, message: "请输入权限名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    openedDialog() {
      if (this.isEdit) {
        this.$nextTick(() => {
          let selectedPath = JSON.parse(this.roleDetail).selectedPath;
          this.$refs.menu.setCheckedKeys(selectedPath);
        });
      }
    },
    closedDialog() {
      this.isEdit = false;
      this.roleDetail = null;
      this.roleId = null;
      this.$set(this, "authorityForm", {
        name: "",
        menu: authorityMenu,
      });
      this.$refs.menu.setCheckedKeys([]);
      //折叠全部节点
      this.$nextTick(() => {
        for (
          let i = 0, len = this.$refs.menu.store._getAllNodes().length;
          i < len;
          i++
        ) {
          this.$refs.menu.store._getAllNodes()[i].expanded = false;
        }
      });
      this.$refs["authorityForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["authorityForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        //菜单列表
        let menuList = [];
        //TAB列表
        let tabList = [];
        //功能按钮列表
        let buttonList = [];
        //可访问路劲名称列表 用作路由守卫判断
        let pathNameList = [];
        let checkedNodes = this.$refs.menu.getCheckedNodes(false, true);
        for (let item of checkedNodes) {
          if (item.pathName) {
            pathNameList.push(item.pathName);
          }
          if (item.level === 0 || item.level === 1) {
            menuList.push(item.path);
          } else if (item.level === 2) {
            tabList.push(item.path);
          } else if (item.level === 3) {
            buttonList.push(item.path);
          }
        }
        let url = "/role/add";
        let params = {
          roleName: this.authorityForm.name,
          roleDetail: JSON.stringify({
            menuList,
            tabList,
            buttonList,
            pathNameList,
            //选中的路径（用作编辑的时候渲染）
            selectedPath: this.$refs.menu.getCheckedKeys(),
          }),
        };
        if (this.isEdit) {
          url = "/role/edit";
          params.roleId = this.roleId;
        }
        let res = await this.$http.post(url, params);
        if (res.code === 0) {
          this.$message.success("编辑成功");
          this.show = false;
          this.$emit("getList");
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addAuthority {
  .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__body {
        .el-form {
          .el-form-item {
            .el-form-item__content {
              .el-input {
                width: 220px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
